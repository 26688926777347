import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ApiService from './common/api.service'
import _ from 'lodash'
import moment from 'moment'
import './filters'

import VueCookies from 'vue-cookies'

// bootstrap vue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// perfect scrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar'

// vue-scrollactive
import VueScrollactive from 'vue-scrollactive'

// vue-meta
import VueMeta from 'vue-meta'

// Sweetalert
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

// portal vue
import PortalVue from 'portal-vue'

// vue-i18n
import i18n from './i18n'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import VueTheMask from 'vue-the-mask'

// set default settings
import appSetting from './app-setting'
// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expire: '30d' })
window.axios = require('axios')

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

moment.locale('pt-Br')

Vue.use(PerfectScrollbar)
Vue.use(VueScrollactive)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
const options = {
  confirmButtonColor: '#4361ee',
  cancelButtonColor: '#e7515a'
}
Vue.use(VueSweetalert2, options)
Vue.use(PortalVue)
Vue.component('VSelect', vSelect)
Vue.use(VueTheMask)

Vue.config.productionTip = false
Vue.prototype.$appSetting = appSetting

Vue.use(_)
ApiService.init()
appSetting.init()

Vue.prototype.$checkRole = function (value) {
  if (store.state.auth.user) {
    if (store.state.auth.user.role === value) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

Vue.prototype.$checkPermission = function (value) {
  if (store.state.auth.userpermissions) {
    if (store.state.auth.userpermissions.includes(value)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
Vue.prototype.$checkWorkPermission = function (value) {
  if (store.state.auth.user.role === 'super-admin') {
    return true
  }
  if (store.state.workData.workUserPermissions) {
    if (store.state.workData.workUserPermissions.includes(value)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
Vue.prototype.$checkCompanyPermission = function (value) {
  if (store.state.auth.companyUserPermissions) {
    if (store.state.auth.companyUserPermissions.includes(value)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
Vue.prototype.$checkArktopRole = function (value) {
  if (store.state.auth.user.roles) {
    if (store.state.auth.user.roles.findIndex((role) => role.name === value) > -1) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

Vue.prototype.$showToast = function (varTitle, varVariant, varMsg) {
  // Use a shorter name for this.$createElement
  const h = this.$createElement
  // Create the message
  const vNodesMsg = h('p', { class: ['text-dark', 'mb-0'] }, [varMsg])
  // Create the title
  const vNodesTitle = h('div', { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] }, [
    h('strong', { class: 'mr-2' }, varTitle),
    h('small', { class: 'ml-auto text-italics' }, moment().format('h:mm:ss'))
  ])
  // Pass the VNodes as an array for message and title
  this.$bvToast.toast([vNodesMsg], {
    title: [vNodesTitle],
    solid: true,
    variant: varVariant,
    autoHideDelay: 10000
  })
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
