import JwtService from '../common/jwt.service'
import ApiService from '../common/api.service'

const types = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT'
}

const state = {
  // logged: !!window.localStorage.getItem("user"),
  logged: !!window.localStorage.getItem('token'),
  user: JSON.parse(window.localStorage.getItem('user')),
  userpermissions: JSON.parse(window.localStorage.getItem('userpermissions')),
  companyUserPermissions: JSON.parse(window.localStorage.getItem('companyUserPermissions'))
  // tokenagent: JSON.parse(window.localStorage.getItem("tokenagent") || null),
}

const mutations = {
  [types.LOGIN] (state) {
    state.logged = true
  },

  [types.LOGOUT] (state) {
    state.logged = false
  }
}

const getters = {
  isLogged: (state) => state.logged,
  userRole: (state) => (state.user ? state.user.role : null)

  // userpermissions: state => state.userpermissions,
  // tokenagent: state => state.tokenagent
}

const actions = {
  login ({ commit }, response) {
    const data = response.data
    JwtService.saveToken(data.access_token)
    state.logged = true
    ApiService.setHeader()
    // state.tokenagent = data.tokenagent;
  },
  authUser ({ commit }, response) {
    const data = response.data
    JwtService.saveUser(data.user)
    JwtService.saveUserPermissions(data.userpermissions)
    JwtService.saveCompanyUserPermissions(data.companyUserPermissions)
    state.user = data.user
    state.userpermissions = data.userpermissions
    state.companyUserPermissions = data.companyUserPermissions
  },

  logout ({ commit }) {
    // $cookies.remove('arktop_navigation_page')
    // $cookies.remove('user_active_company')
    // $cookies.remove('user_companies')

    ApiService.get('auth/logout')
      .then((response) => {
        if (response.data.status === 'success') {
          ApiService.destroyHeader()
          state.user = null
          state.userpermissions = null
          state.logged = false
          // state.tokenagent = null;
          state.userRole = null
          JwtService.destroyToken()
          // JwtService.destroyAWSToken();
          JwtService.destroyUser()
          // JwtService.destroyTokenagent();
          JwtService.destroyUserPermissions()
          JwtService.destroyCompanyUserPermissions()
        } else {
          alert('Erro!')
        }
      })
      .finally(() => {
        window.location.href = '/login'
      })
  },
  logoutInvalidToken ({ commit }) {
    ApiService.destroyHeader()
    state.user = null
    state.userpermissions = null
    state.companyUserPermissions = null
    state.logged = false
    // state.tokenagent = null;
    state.userRole = null
    JwtService.destroyToken()
    JwtService.destroyUser()
    // JwtService.destroyTokenagent();
    JwtService.destroyUserPermissions()
    JwtService.destroyCompanyUserPermissions()
  },

  changeAvatar ({ commit }, avatar) {
    // console.log(avatar);
    state.user.avatar = avatar
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
